import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '../service/rest.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  nom_op:string;
  nom_vend:string;
  rut_vend:string;
  email_vend:string;
  nom_comp:string;
  rut_comp:string;
  email_comp:string;
  fch_fir_cv:string;
  plazos:string;
  valor_venta:string;
  tipo_op:string;
  abogado:string;
  eje_vende:string;
  nom_ejec:string;
  email_ejec:string;
  num_carp:string;
  color:string="white";

  constructor(private _RestService: RestService, private router: Router) { }

  ngOnInit(): void {
  }

  createProfile(){
    if(this.nom_op != "" && this.nom_vend != "" && this.rut_vend != "" 
    && this.email_vend != "" && this.nom_comp != "" && this.rut_comp != ""
    && this.email_comp != "" && this.fch_fir_cv != "" && this.nom_ejec != ""
    && this.email_ejec != "" && this.num_carp != ""){

      console.log(this.nom_op);
      const data = {
        vendedor: this.nom_vend,
        rut_vendedor: this.rut_vend,
        email_vendedor: this.email_vend,
        comprador: this.nom_comp,
        rut_comprador: this.rut_comp,
        email_comprador: this.email_comp,
        fch_firm_cv: this.fch_fir_cv,
        plazos: this.plazos,
        valor_venta: this.valor_venta,
        tipo_op: this.tipo_op,
        abogado: this.abogado,
        eje_vende: this.eje_vende,
        ejecutiva: this.nom_ejec,
        email_ejecutiva: this.email_ejec,
        carpeta: this.num_carp,
        nom_op: this.nom_op,
        color: this.color
      }

      this._RestService.createProfile(data).subscribe((res:any)=>{
        console.log(res);
        window.location.href="https://www.ehseguimiento.cl/profile-edit/"+res.id;
        //window.location.href="http://localhost:4200/profile-edit/"+res.id;
      })

    }
  }

}
