import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email:string;
  pass:string;

  seguimiento:string;
  codigo:string;

  constructor(private _AuthService: AuthService, private router: Router) {}

  ngOnInit(): void {
  }

  loginUser(){

    if(this.email != "" && this.pass != ""){

      const data = {
        correo:this.email,
        password:this.pass
      }
      this._AuthService.logIn(data).subscribe((res:Request)=>{
        console.log(res);
        
      })
    }
    else{
      console.log("Debe ingresar todos los campos");
    }
  }

  logOut(){
    this._AuthService.logOut();
  }

  trakking(){
    if(this.seguimiento != "" && this.codigo != ""){

      //profile/100011/345

      //console.log(this.seguimiento + " " + this.codigo)
      this.router.navigate(['profile/'+this.seguimiento+'/'+this.codigo]);
    }  
  }

}
