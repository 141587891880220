<div class="container color-gris pt-5 pb-5">
    <div class="row">
        <div class="col-md-3">
            <img width="200" src="/assets/images/EH_LOGO-02.png">
        </div>
        <div class="col-md-6 mt-5">
            <p>
              <strong>Operación:</strong> {{profile.nom_op}}<br>
            </p>
            <p>
                <strong>Vendedor:</strong> {{profile.vendedor}}<br>
                <strong>Rut:</strong> {{profile.rut_vendedor}}<br>
            </p>
            <p>
              <strong>Comprador:</strong> {{profile.comprador}}<br>
              <strong>Rut:</strong> {{profile.rut_comprador}}<br>
            </p>    
            <p><strong>Fecha de firma promesa de CV:</strong> {{profile.fch_firm_cv}}</p>
            <p>
            <p>
              <strong>Ejecutiva comercial:</strong> {{profile.ejecutiva}}<br>
                <strong>Email:</strong> {{profile.email_ejecutiva}}
            </p>
            <p>
              <strong>Carpeta:</strong> {{profile.carpeta}}<br>
            </p>
            
        </div>
        <div class="col-md-3 mt-5">
          <div class="card">
            <div class="card-body">
              <h4>N° SEGUIMIENTO</h4>
              <h1>{{profile.idficha}}</h1>
            </div>
          </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
        
            <ul class="nav nav-tabs">
                
                <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#menu1" ><strong>SEGUIMIENTO/ESTADO OP</strong></a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu2"><strong>CONTACTOS A CARGO OP</strong></a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu3"><strong>ARCHIVOS RELACIONADOS</strong></a></li>
              </ul>
              <div class="tab-content">
                
                <div id="menu1" class="tab-pane fade active show">

                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">FECHA</th>
                            <th scope="col">ETAPA</th>
                            <th scope="col">ESTADO DE OPERACIÓN/ SEGUIMIENTO</th>
                            <th scope="col">OBSERVACIONES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let est of estados">
                            <td>{{est.fecha}}</td>
                            <td>{{est.etapa}}</td>
                            <td>{{est.estado_op}}</td>
                            <td>{{est.descripcion}}</td>
                          </tr>
                        </tbody>
                      </table>

                </div>


                <div id="menu2" class="tab-pane fade">
                    <table class="table">
                        <thead>
                          <tr>
                            
                            <th scope="col">Nombre</th>
                            <th scope="col">Cargo</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let con of contactos">
                            
                            <td>{{con.nombre}}</td>
                            <td>{{con.cargo}}</td>
                            <td>{{con.telefono}}</td>
                            <td>{{con.correo}}</td>
                          </tr>
                          
                        </tbody>
                      </table>
              
                </div>


                <div id="menu3" class="tab-pane fade">
                  <table class="table">
                    <thead>
                      <tr>
                        
                        <th scope="col">Fecha</th>
                        <th scope="col">Responsable</th>
                        <th scope="col">Documento</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let up of uploads">
                        
                        <td>{{up.fecha | date: 'dd/MM/yyyy'}}</td>
                        <td>{{up.nombre}}</td>
                        <td>{{up.ruta.toString().split("/")[2]}}</td>
                        <td><a class="est-del" (click)="viewUpload(up.iddoc,up.ruta )"><i class="bi bi-eye"></i></a></td>
                      </tr>

                      
                    </tbody>
                  </table>
                  <div class="input-group">
                    
                    <div class="custom-file">
                        <form action="/api/upload" method="post" enctype="multipart/form-data">
                          <input (change)="fileChange($event)" type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                        </form>
                        <label class="custom-file-label" for="inputGroupFile01">{{fileName}}</label>
                    </div>
                    <div class="input-group-prepend">
                      <span  class="input-group-text upload" (click)="upload()" id="btnUpload">Subir</span>
                    </div>
                  </div>
                  
                    
                </div>
              </div>
        
        </div>
           
    </div>


</div>



