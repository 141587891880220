import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestService } from '../service/rest.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  id: string;
  codigo: string;
  profile: any = [];
  estados: any = [];
  contactos: any = [];

  uploads: any = [];
  uploadedFiles:any = [];
  fileName:string = "Seleccione..."

  constructor(private Route: ActivatedRoute, private _Router:Router, private _RestService: RestService) { }

  ngOnInit(): void {
    this.id = this.Route.snapshot.paramMap.get('id');
    this.codigo = this.Route.snapshot.paramMap.get('code');

    
    this._RestService.getProfileCode(this.id,this.codigo).subscribe((res:any)=>{
      if(!res.hasOwnProperty('message')){
        
        this._RestService.getProfile(this.id).subscribe((res:any)=>{
        
          this.profile = res;
  
          console.log(res);
  
          this._RestService.getEstados(this.id).subscribe((res:[])=>{
            console.log(res);
            if(!res.hasOwnProperty('message')){
              this.estados = res;
            }
        
          })
  
          this._RestService.getContactos(this.id).subscribe((res:[])=>{
            console.log(res);
            if(!res.hasOwnProperty('message')){
              this.contactos = res;
            }
        
          })

          this._RestService.getUploads(this.id).subscribe((res:[])=>{
            console.log(res);
            if(!res.hasOwnProperty('message')){
              this.uploads = res;
            }
        
          });
  
        })

      }
      else{
        this._Router.navigate(['login']);
      }
    })


  }

  fileChange(element) {
    this.fileName = element.target.files[0].name;
    this.uploadedFiles = element.target.files;
  }

  upload() {
    let formData = new FormData();
    for (var i = 0; i < this.uploadedFiles.length; i++) {
        formData.append("uploads[]", this.uploadedFiles[i], this.uploadedFiles[i].name);
    }
    formData.append("nombre","Usuario");
    formData.append('ficha',this.id);
    console.log(formData);

    this._RestService.uploadImage(formData).subscribe((res: any)=>{
        console.log(res);
        this._RestService.getUploads(this.id).subscribe((res:[])=>{
          console.log(res);
          if(!res.hasOwnProperty('message')){
            this.uploads = res;
          }
      
        });

    })

  }

  viewUpload(id, name){
   const res = this._RestService.viewUpload(id).subscribe((data:any) => {

    let nombre = name.split("/");
  
    var downloadURL = window.URL.createObjectURL(data);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = nombre[2];
    link.click();
  
  });
  }
  deleteUpload(id){
    this._RestService.deleteDocument(id).subscribe((res:[])=>{
      console.log(res);
      this._RestService.getContactos(this.id).subscribe((resp:[])=>{
        console.log(resp);
        if(!resp.hasOwnProperty('message')){
          
          resp.sort(function(a,b){
            return b - a;
          });
          this._RestService.getUploads(this.id).subscribe((res:[])=>{
            console.log(res);
            if(!res.hasOwnProperty('message')){
              this.uploads = res;
            }
        
          });
        }
        
    
      })
    })
  }

}
