<app-navbar></app-navbar>

  <main role="main" class="container">
    <div class="row">
        <div class="col-md-12 text-center">
            <h1 class="welcome">Operaciones!</h1>
            <h3>Administra las Operaciones</h3>
        </div>
    </div>
    <div class="row mt-5" style="padding-bottom:100px">
      <div class="col-md-12">
        <div>
          <input
            type="text"
            style="padding:8px;margin:15px auto;width:30%;"
            placeholder="Buscar operación"
            (keyup)="filterDatatable($event)"
          />
          
          <a href="#" style="margin-top:20px" class="btn btn-danger float-right" data-toggle="modal" data-target="#exampleModal">Crear operación</a>
          <ngx-datatable
            #table
            class="material"
            [columns]="columns"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            rowHeight="auto"
            [limit]="5"
            [rows]="rows"

          >



          <ngx-datatable-column name="Código" sortable="false" prop="idficha">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                  {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Operación" sortable="false" prop="Operacion">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                  {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Encargada" sortable="false" prop="Encargada">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                  {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Color" sortable="false" prop="Color">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                  
                  <span class="ml-4"><i class="bi bi-circle-fill {{value}}"></i></span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Nº Carpeta" sortable="false" prop="Nº Carpeta">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                  {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Acciones" sortable="false" prop="Acciones">
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                  <a href="profile-edit/{{value}}" class="card-link">Ver <i class="bi bi-eye"></i> </a>
                  <a *ngIf="tipo" (mousedown)="deleteProfile(value)" class="text-right trash"><i class="bi bi-trash"></i></a>
            </ng-template>
          </ngx-datatable-column>

          </ngx-datatable>
        </div>
      </div>
    </div>


  </main><!-- /.container -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Crear nueva Operación</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Nombre Operación</label>
                <input type="text" class="form-control" name="nom_op" [(ngModel)]="nom_op" placeholder="Nombre Operación">
              </div>
            </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Nombre vendedor</label>
                  <input type="text" class="form-control" name="nom_vend" [(ngModel)]="nom_vend" placeholder="nombre completo">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Rut vendedor</label>
                  <input type="text" class="form-control" name="rut_vend" [(ngModel)]="rut_vend" placeholder="1111111-1">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Email vendedor</label>
                  <input type="text" class="form-control" name="email_vend" [(ngModel)]="email_vend" placeholder="Correo Electrónico">
                </div>
              </div>
              <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Nombre comprador</label>
                    <input type="text" class="form-control" name="nom_comp" [(ngModel)]="nom_comp" placeholder="nombre completo">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Rut comprador</label>
                    <input type="text" class="form-control" name="rut_comp" [(ngModel)]="rut_comp" placeholder="1111111-1">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Email comprador</label>
                    <input type="text" class="form-control" name="email_comp" [(ngModel)]="email_comp" placeholder="Correo Electrónico">
                  </div>
                </div>

              <div class="form-row">
                  <div class="form-group col-md-6">
                      <div class="form-group">
                      <label for="inputAddress">Fecha de firma promesa de CV</label>
                      <input type="text" class="form-control" name="fch_fir_cv" [(ngModel)]="fch_fir_cv" placeholder="10/05/2020">
                      </div>
                  </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputAddress">Plazos</label>
                    <input type="text" class="form-control" name="plazos" [(ngModel)]="plazos" placeholder="">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputAddress">Valor venta</label>
                  <input type="text" class="form-control" name="valor_venta" [(ngModel)]="valor_venta" placeholder=""> 
                </div>
              </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                  
                    <label for="inputAddress">Tipo de OP</label>
                    <input type="text" class="form-control" name="tipo_op" [(ngModel)]="tipo_op" placeholder="">
                  
              </div>
              <div class="form-group col-md-6">
               
                <label for="inputAddress">Abogado a cargo</label>
                <input type="text" class="form-control" name="abogado" [(ngModel)]="abogado" placeholder="">
                
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                 
                  <label for="inputAddress">Ejecutiva(s) vendedoras</label>
                  <input type="text" class="form-control" name="eje_vende" [(ngModel)]="eje_vende" placeholder="">
                 
              </div>
            </div>


              <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Ejecutivo Ehrenfeld</label>
                    <input type="text" class="form-control" name ="nom_ejec" [(ngModel)]="nom_ejec" placeholder="nombre completo">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Email ejecutivo Ehrenfeld</label>
                    <input type="email" class="form-control" name="email_ejec" [(ngModel)]="email_ejec" placeholder="Correo Electrónico">
                  </div>
                </div>
            </form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Nº Carpeta</label>
                <input type="text" class="form-control" [(ngModel)]="num_carp" placeholder="Nº Carpeta">
              </div>
              <div class="form-group col-md-6">
                <label for="inputEmail4">Seleccione color</label>
                <select name="op_etapa" class="form-control" [(ngModel)]="color">
                  <option value="1">Seleccione ...</option>
                  <option value="Red">Rojo</option>
                  <option value="Lime">Limón</option>
                  <option value="Aqua">Aqua</option>
                  <option value="Fuchsia">fucsia</option>
                  <option value="Blue">Azul</option>
                </select>
                <span class="show-color"><i class="bi bi-circle-fill" style="color:{{color}}"></i></span>
              </div>
            </div>
            

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="createProfile()" data-dismiss="modal">Guardar</button>
      </div>
    </div>
  </div>
</div>