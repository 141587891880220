import { Injectable } from '@angular/core';
import { apiUrl } from 'src/environments/environment';
import { tap, catchError} from 'rxjs/operators'
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})

export class RestService{


    constructor(private _Http:HttpClient, private _Router:Router){}

    createProfile(data){
        return this._Http.post(apiUrl + 'profile', data).pipe(

            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    getProfiles(){
        return this._Http.get(apiUrl+'profiles').pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }

    getProfile(id){
        return this._Http.get(apiUrl+'profile/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }

    getProfileCode(id,code){
        return this._Http.get(apiUrl+'profile/'+id+'/'+code).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }


    updateProfile(data, id){
        return this._Http.put(apiUrl + 'profile/'+id, data).pipe(
            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    deleteProfile(id){
        return this._Http.delete(apiUrl+'profile/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }


    createEstado(data){
        return this._Http.post(apiUrl + 'trakking', data).pipe(

            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    updateState(id,data){
        return this._Http.put(apiUrl + 'trakking/'+id,data).pipe(
            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    getEstados(id){
        return this._Http.get(apiUrl+'trakking/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }

    deleteEstado(id){
        return this._Http.delete(apiUrl+'trakking/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }


    createContacto(data){
        return this._Http.post(apiUrl + 'contact', data).pipe(

            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    getContactos(id){
        return this._Http.get(apiUrl+'contact/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }


    updateContacto(id,data){
        return this._Http.put(apiUrl + 'contact/'+id,data).pipe(
            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    deleteContacto(id){
        return this._Http.delete(apiUrl+'contact/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }

    uploadImage(formData){
        return this._Http.post(apiUrl + 'upload',formData).pipe(

            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    getUploads(id){
        return this._Http.get(apiUrl+'upload/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }

    viewUpload(id){


        const httpOptions = {
            responseType: 'blob' as 'json',
          };
        
          return this._Http.get(apiUrl+'upload/document/'+id, httpOptions)


    }

    deleteDocument(id) {
        return this._Http.delete(apiUrl+'upload/document/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }

    createUser(data){
        return this._Http.post(apiUrl + 'user', data).pipe(

            catchError((err)=>{
                const {error} = err

                return new Observable((res:any)=>{
                    const reqData = {
                        message:error.message,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })
        )
    }

    getUsers(){
        return this._Http.get(apiUrl+'users').pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }

    updateUser(id,data){
        return this._Http.put(apiUrl + 'user/'+id,data).pipe(
            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    updateUserPass(id,data){
        return this._Http.put(apiUrl + 'user-pass/'+id,data).pipe(
            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }

    deleteUser(id) {
        return this._Http.delete(apiUrl+'user/'+id).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }


    getClientes(){
        
            return this._Http.get(apiUrl+'clientes').pipe(
    
                catchError((error)=>{
                    return new Observable((res)=>{
                        const reqData = {
                            message:error.statusText,
                            status:error.status
                        }
    
                        res.next(reqData);
                    })
                })
    
            )
        
    }



}