import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { ProfileEditorComponent} from './profile-editor/profile-editor.component';
import { ProfilesViewComponent} from './profiles-view/profiles-view.component';
import { UsuariosComponent} from './usuarios/usuarios.component';
import { ClientesComponent} from './clientes/clientes.component';
import { AuthGuard} from './auth.guard';


const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'profile/:id/:code', component: ProfileComponent},
  { path: 'profile-edit/:id', component: ProfileEditorComponent, canActivate: [AuthGuard] },
  { path: 'profile-view', component: ProfilesViewComponent, canActivate: [AuthGuard] },
  { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard] },
  { path: 'clientes', component: ClientesComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
