import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RestService } from '../service/rest.service';
declare var require: any;
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;



@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.css']
})
export class ProfileEditorComponent implements OnInit {

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;

  tipo: boolean;


  id: string;
  profile: any = [];
  estados: any = [];
  contactos: any = [];
  uploads: any = [];

  
  nom_op: string = "";
  nom_vend: string;
  rut_vend: string;
  email_vend: string;
  nom_comp: string;
  rut_comp: string;
  email_comp: string;
  fch_fir_cv: string;
  plazos:string;
  valor_venta:string;
  tipo_op:string;
  abogado:string;
  eje_vende:string;
  nom_ejec: string;
  email_ejec: string;
  num_carp: string;
  color:string="white";

  op_id: string = "";
  op_fecha: string;
  op_etapa: string = "1";
  op_estado: string;
  op_obs: string;

  con_id: string;
  con_nombre: string;
  con_cargo: string;
  con_tel: string;
  con_correo: string;
  con_ficha: string;

  uploadedFiles:any = [];
  fileName:string = "Seleccione..."

  errorEstado:string = "";

  error: string = "";

  constructor(private Route: ActivatedRoute, private _Router:Router, private _RestService: RestService) { }

  ngOnInit(): void {


    this.tipo = localStorage.getItem('Profile') == "2" ? true : false ;

    this.id = this.Route.snapshot.paramMap.get('id');
    console.log(this.id);

    this._RestService.getProfile(this.id).subscribe((res:any)=>{
      
      this.profile = res;

      console.log(res);

      this.nom_op = res.nom_op;
      this.nom_vend = res.vendedor;
      this.rut_vend = res.rut_vendedor;
      this.email_vend = res.email_vendedor;
      this.nom_comp = res.comprador;
      this.rut_comp = res.rut_comprador;
      this.email_comp = res.email_comprador;
      this.fch_fir_cv = res.fch_firm_cv;
      this.plazos = res.plazos;
      this.valor_venta = res.valor_venta;
      this.tipo_op = res.tipo_op;
      this.abogado = res.abogado;
      this.eje_vende = res.eje_vende;
      this.nom_ejec = res.ejecutiva;
      this.email_ejec = res.email_ejecutiva;
      this.num_carp = res.carpeta;
      this.color = res.color;

      this._RestService.getEstados(this.id).subscribe((res:[])=>{
        console.log(res);
        if(!res.hasOwnProperty('message')){
          res.sort(function(a:any,b:any){
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return +b.fecha - +a.fecha;
          });
          this.estados = res;
        }
    
      })

      this._RestService.getContactos(this.id).subscribe((res:[])=>{
        console.log(res);
        if(!res.hasOwnProperty('message')){
          this.contactos = res;
        }
    
      });
      this._RestService.getUploads(this.id).subscribe((res:[])=>{
        console.log(res);
        if(!res.hasOwnProperty('message')){
          this.uploads = res;
        }
    
      });



    })
   
  }

  updateProfile(){
    if(this.nom_op != "" && this.nom_vend != "" && this.rut_vend != "" 
    && this.email_vend != "" && this.nom_comp != "" && this.rut_comp != ""
    && this.email_comp != "" && this.fch_fir_cv != "" && this.nom_ejec != ""
    && this.email_ejec != "" && this.num_carp != ""){

      console.log(this.nom_op);
      const data = {
        vendedor: this.nom_vend,
        rut_vendedor: this.rut_vend,
        email_vendedor: this.email_vend,
        comprador: this.nom_comp,
        rut_comprador: this.rut_comp,
        email_comprador: this.email_comp,
        fch_firm_cv: this.fch_fir_cv,
        plazos: this.plazos,
        valor_venta: this.valor_venta,
        tipo_op: this.tipo_op,
        abogado: this.abogado,
        eje_vende: this.eje_vende,
        ejecutiva: this.nom_ejec,
        email_ejecutiva: this.email_ejec,
        carpeta: this.num_carp,
        nom_op: this.nom_op,
        color: this.color
      }

      this._RestService.updateProfile(data, this.id).subscribe((res: any)=>{
        console.log(res);
        
        this.profile.nom_op = res.nom_op;
        this.profile.vendedor = res.vendedor;
        this.profile.rut_vendedor = res.rut_vendedor;
        this.profile.email_vendedor = res.email_vendedor;
        this.profile.comprador = res.comprador;
        this.profile.rut_comprador = res.rut_comprador;
        this.profile.email_comprador = res.email_comprador;
        this.profile.fch_firm_cv = res.fch_firm_cv;
        this.plazos = res.plazos;
        this.valor_venta = res.valor_venta;
        this.tipo_op = res.tipo_op;
        this.abogado = res.abogado;
        this.eje_vende = res.eje_vende;
        this.profile.ejecutiva = res.ejecutiva;
        this.profile.email_ejecutiva = res.email_ejecutiva;
        this.profile.carpeta = res.carpeta;
        this.profile.color = res.color;
        location.reload();
      })

    }
  }

  createState(event: any){

    if(this.op_estado != "" && this.op_etapa != "1" && this.op_fecha != "" && this.op_obs != ""){
      this.errorEstado = "";
      const regex : RegExp = /^(\d{2})[\/](\d{2})[\/]\d{4}$/;
      if(!regex.test(this.op_fecha)){
          console.log("Fecha invalida");
          this.errorEstado = "Debe ingresar una fecha valida (dd/mm/YYYY)";
          return false;
      }

      const data = {
        estado_op: this.op_estado,
        descripcion: this.op_obs,
        etapa: this.op_etapa,
        ficha: this.id,
        fecha: this.op_fecha
      }

      this._RestService.createEstado(data).subscribe((res: any)=>{
        console.log(res);
        this._RestService.getEstados(this.id).subscribe((res:[])=>{
          console.log(res);
          if(!res.hasOwnProperty('message')){
            res.sort(function(a:any,b:any){
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return +b.fecha - +a.fecha;
            });
            this.estados = res;
          }
      
        })
      }) 
    }

  }


  editState(id,fecha,etapa,estado,obs){
    this.error = "";
    this.op_id = id;
    this.op_fecha = fecha;
    this.op_etapa = etapa;
    this.op_estado = estado;
    this.op_obs = obs;

    console.log(id);
  }

  updateState(id){
    if(this.op_fecha != "" && this.op_etapa != "" &&
    this.op_estado != "" && this.op_obs != ""){

      const data = {
        estado_op: this.op_estado,
        descripcion: this.op_obs,
        etapa: this.op_etapa,
        ficha: this.id,
        fecha: this.op_fecha
      }

      this._RestService.updateState(id, data).subscribe((res: any)=>{
        console.log(res);
        if(res.message == "OK"){
          this.error = "Editado correctamente";
          this._RestService.getEstados(this.id).subscribe((res:[])=>{
            console.log(res);
            if(!res.hasOwnProperty('message')){
              res.sort(function(a:any,b:any){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return +b.fecha - +a.fecha;
              });
              this.estados = res;
            }
        
          })
        }
      });
    }else{
      this.error = "Debe completar todos los campos.";
    }

  }



  deleteEstate(idestado: any){
    if(confirm('Desea eliminar el estado?')){
      this._RestService.deleteEstado(idestado).subscribe((res:[])=>{
        this.estados = [];
        console.log(res);
        this._RestService.getEstados(this.id).subscribe((resp:[])=>{
          console.log(resp);
          if(!resp.hasOwnProperty('message')){
            
            resp.sort(function(a,b){
              return b - a;
            });

            this.estados = resp;
          }
          
      
        })
      })
    }
  }


  createContacto(event: any){

    console.log(event.parentNode);

    if(this.con_nombre != "" && this.con_cargo != "" && this.con_tel != "" && this.con_correo != ""){

      const data = {
        nombre: this.con_nombre,
        correo: this.con_correo,
        telefono: this.con_tel,
        cargo: this.con_cargo,
        ficha: this.id
      }

      this._RestService.createContacto(data).subscribe((res: any)=>{
        console.log(res);
        this._RestService.getContactos(this.id).subscribe((res:[])=>{
          console.log(res);
          if(!res.hasOwnProperty('message')){
            this.contactos = res;
          }
      
        })
      }) 
    }

  }



  editContacto(id,nombre,cargo,telefono,correo){
    this.con_id = id;
    this.con_nombre = nombre;
    this.con_cargo = cargo;
    this.con_tel = telefono;
    this.con_correo = correo;
    this.con_ficha = this.id

    console.log(id);
  }

  updateContacto(id){
    if(this.con_nombre != "" && this.con_cargo != "" &&
    this.con_tel != "" && this.con_correo != ""){

      const data = {
        nombre: this.con_nombre,
        correo: this.con_correo,
        telefono: this.con_tel,
        cargo: this.con_cargo,
        ficha: this.con_ficha
      }

      this._RestService.updateContacto(this.con_id, data).subscribe((res: any)=>{
        console.log(res);
        if(res.message == "OK"){
          this.error = "Editado correctamente";
          this._RestService.getContactos(this.id).subscribe((res:[])=>{
            console.log(res);
            if(!res.hasOwnProperty('message')){
              res.sort(function(a:any,b:any){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return +b.fecha - +a.fecha;
              });
              this.contactos = res;
            }
        
          })
        }
      });
    }else{
      this.error = "Debe completar todos los campos.";
    }

  }



  deleteContacto(idcontacto: any){
    this._RestService.deleteContacto(idcontacto).subscribe((res:[])=>{
      console.log(res);
      this.contactos = [];
      this._RestService.getContactos(this.id).subscribe((resp:[])=>{
        console.log(resp);
        if(!resp.hasOwnProperty('message')){
         
          this.contactos = resp;
        }
        
    
      })
    })
  }

  fileChange(element) {
    this.fileName = element.target.files[0].name;
    this.uploadedFiles = element.target.files;
  }

  upload() {
    let formData = new FormData();
    for (var i = 0; i < this.uploadedFiles.length; i++) {
        formData.append("uploads[]", this.uploadedFiles[i], this.uploadedFiles[i].name);
    }
    formData.append("nombre",localStorage.getItem("Nombre"));
    formData.append('ficha',this.id);
    console.log(formData);

    this._RestService.uploadImage(formData).subscribe((res: any)=>{
        console.log(res);
        this._RestService.getUploads(this.id).subscribe((res:[])=>{
          console.log(res);
          if(!res.hasOwnProperty('message')){
            this.uploads = res;
          }
      
        });

    })

  }

  viewUpload(id, name){
   const res = this._RestService.viewUpload(id).subscribe((data:any) => {

    let nombre = name.split("/");
  
    var downloadURL = window.URL.createObjectURL(data);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = nombre[2];
    link.click();
  
  });
  }
  deleteUpload(id){
    this._RestService.deleteDocument(id).subscribe((res:[])=>{
      console.log(res);
      this.uploads = [];
      this._RestService.getContactos(this.id).subscribe((resp:[])=>{
        console.log(resp);
        if(!resp.hasOwnProperty('message')){
          
          this._RestService.getUploads(this.id).subscribe((res:[])=>{
            console.log(res);
            if(!res.hasOwnProperty('message')){
              
              this.uploads = res;
            }
        
          });
        }
        
    
      })
    })
  }


  public downloadAsPDF() {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
   pdfMake.createPdf(documentDefinition).open();
     
  }

  public convetToPDF() {
    var data = document.getElementById('pdfTable');
    html2canvas(data).then(canvas => {
      var imgData = canvas.toDataURL('image/png', 0.3);
      var imgWidth = 210; 
      var pageHeight = 295;  
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jspdf('p', 'mm', 'a4', true);
      var position = 0;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight,'FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight,'FAST');
        heightLeft -= pageHeight;
      }
      doc.save( this.id+'.pdf');
       // Few necessary setting options
      //  var imgWidth = 208;
      //  var pageHeight = 295;
      //  var imgHeight = canvas.height * imgWidth / canvas.width;
      //  var heightLeft = imgHeight;

      //  const contentDataURL = canvas.toDataURL('image/png')
      //  let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
      //  var position = 0;
      //  pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      //  pdf.save('new-file.pdf'); // Generated PDF
    });
 }


}


