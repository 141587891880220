import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { RestService } from '../service/rest.service';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-profiles-view',
  templateUrl: './profiles-view.component.html',
  styleUrls: ['./profiles-view.component.css']
})
export class ProfilesViewComponent implements OnInit {

  tipo: boolean;

  data: Array<{idficha: number, Operacion: string,'Encargada': string, Color: string,'Nº Carpeta': string, 'Acciones': string, 'Acciones2': string}> = []; 
  rows = [];

  filteredData = [];
  columnsWithSearch : string[] = [];
  selected: any[] = [];
  SelectionType = SelectionType;

  temp = [];
  columns = [{ prop: 'idficha',name:'Nº Ficha' }, { prop: 'Operacion' }, { prop: 'Encargada' }, { prop: 'Color' }, { prop: 'Nº Carpeta' }, { prop: 'Acciones' }, { prop: 'Acciones2' }];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  encapsulation: ViewEncapsulation.None;

  ColumnMode = ColumnMode;

  profiles:[];

  nom_op:string;
  nom_vend:string;
  rut_vend:string;
  email_vend:string;
  nom_comp:string;
  rut_comp:string;
  email_comp:string;
  fch_fir_cv:string;
  plazos:string;
  valor_venta:string;
  tipo_op:string;
  abogado:string;
  eje_vende:string;
  nom_ejec:string;
  email_ejec:string;
  num_carp:string;
  color:string="white";

  constructor(private _RestService: RestService) { }

  ngOnInit(): void {
    this.getProfiles();
    this.tipo = localStorage.getItem('Profile') == "2" ? true : false ;
    
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    console.log(val);
    // filter our data
    const temp = this.temp.filter(function (d) {
      console.log(d['idficha']);
      return d.name.indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  getProfiles(){
    this.profiles = [];
    this.data = [];


    this._RestService.getProfiles().subscribe((res:any)=>{
      console.log(res);
      this.profiles = res;
      
      

      res.forEach(e => {
        //console.log(e['idficha']);
   
        let color = e['color'];
        let actions = e['idficha'];
        let actions2 = e['idficha'];
        
        this.data.push( {idficha: e['idficha'], Operacion: e['nom_op'], 'Encargada': e['ejecutiva'], Color: color,'Nº Carpeta': e['carpeta'], Acciones: actions, Acciones2: actions2});
        
      });
      console.log(...this.data);

      
      this.rows = [...this.data];
      this.filteredData = this.rows;
      this.columnsWithSearch = Object.keys(this.rows[0]);
    })
  }

  createProfile(){
    if(this.nom_op != "" && this.nom_vend != "" && this.rut_vend != "" 
    && this.email_vend != "" && this.nom_comp != "" && this.rut_comp != ""
    && this.email_comp != "" && this.fch_fir_cv != "" && this.nom_ejec != ""
    && this.email_ejec != "" && this.num_carp != ""){

      console.log(this.nom_op);
      const data = {
        vendedor: this.nom_vend,
        rut_vendedor: this.rut_vend,
        email_vendedor: this.email_vend,
        comprador: this.nom_comp,
        rut_comprador: this.rut_comp,
        email_comprador: this.email_comp,
        fch_firm_cv: this.fch_fir_cv,
        plazos: this.plazos,
        valor_venta: this.valor_venta,
        tipo_op: this.tipo_op,
        abogado: this.abogado,
        eje_vende: this.eje_vende,
        ejecutiva: this.nom_ejec,
        email_ejecutiva: this.email_ejec,
        carpeta: this.num_carp,
        nom_op: this.nom_op,
        color: this.color
      }

      this._RestService.createProfile(data).subscribe((res:any)=>{
        console.log(res);
        this.getProfiles();
        this.clear();
      })

    }
  }
  deleteProfile(id){
    if(confirm('Desea eliminar la operación '+id+' ?')){
      this._RestService.deleteProfile(id).subscribe((res:[])=>{
        console.log(res);
        
          this.getProfiles()
    
        
      })
    }
  }

    
  

  onActivate(event) {
    // if(event.type == 'click') {
    //     console.log(event.row);
    // }
  }

  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);
  }

  algo(algo) {
    console.log(algo);
  }




  clear(){
    this.nom_op = "";
    this.nom_vend = "";
    this.rut_vend = "";
    this.email_vend = "";
    this.nom_comp = "";
    this.rut_comp = "";
    this.email_comp = "";
    this.fch_fir_cv = "";
    this.nom_ejec = "";
    this.email_ejec = "";
    this.num_carp = "";
  }




  // filters results
filterDatatable(event){
  // get the value of the key pressed and make it lowercase
  let filter = event.target.value.toLowerCase();

  // assign filtered matches to the active datatable
  this.rows = this.filteredData.filter(item => {
    // iterate through each row's column data
    for (let i = 0; i < this.columnsWithSearch.length; i++){
      var colValue = item[this.columnsWithSearch[i]] ;

      // if no filter OR colvalue is NOT null AND contains the given filter
      if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
        // found match, return true to add to result set
        return true;
      }
    }
  });
  // TODO - whenever the filter changes, always go back to the first page
  //this.table.offset = 0;
}




}
