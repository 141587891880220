<app-navbar></app-navbar>

  <main role="main" class="container">
    <div class="row">
        <div class="col-md-12 text-center">
            <h1 class="welcome">Clientes!</h1>
            <h3>Administra los clientes del sistema</h3>
        </div>
    </div>
    <div class="row mt-5" style="padding-bottom:100px">
        <div class="col-md-12">
          <div>
            <input
              type="text"
              style="padding:8px;margin:15px auto;width:30%;"
              placeholder="Buscar clientes"
              (keyup)="filterDatatable($event)"
            />
            
            
            <ngx-datatable
              #table
              class="material"
              [columns]="columns"
              [columnMode]="'force'"
              [headerHeight]="50"
              [footerHeight]="50"
              rowHeight="auto"
              [limit]="10"
              [rows]="rows"
  
            >
  
  
  
            <ngx-datatable-column name="Nombre" sortable="false" prop="Nombre">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                    {{value}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Rut" sortable="false" prop="Rut">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                    {{value}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" sortable="false" prop="Email">
              <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>      
                    {{value}}
              </ng-template>
            </ngx-datatable-column>
  
            </ngx-datatable>
          </div>
        </div>
      </div>





  </main><!-- /.container -->

