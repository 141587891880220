<app-navbar></app-navbar>

  <main role="main" class="container">
    <div class="row">
        <div class="col-md-12 text-center">
            <h1 class="welcome">Usuarios!</h1>
            <h3>Administra los usuarios del sistema</h3>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-10 offset-md-1">
            <button class="btn btn-outline-danger" data-toggle="modal" data-target="#nuevoUsuario">Nuevo usuario</button>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-md-10 offset-md-1">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Correo</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Opciones</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let u of users">
                    <td>{{u.nombre}} {{u.apellido}}</td>
                    <td>{{u.correo}}</td>
                    <td>{{u.telefono}}</td>
                    <td>
                        <a class="icon-link" title="Editar Usuario" data-toggle="modal" (click)="editUser(u.id_user,u.nombre,u.apellido,u.correo,u.telefono)" data-target="#editarUsuario"><i class="bi bi-pencil-square"></i></a>
                        <a class="icon-link ml-2" title="Cambiar la contraseña" (click)="editUser(u.id_user,u.nombre,u.apellido,u.correo,u.telefono)" data-toggle="modal" data-target="#editarPass"><i class="bi bi-file-lock"></i></a>
                        <a class="icon-link ml-2" title="Eliminar" (click)="delUser(u.id_user)"><i class="bi bi-trash"></i></a>
                    </td>
                    
                </tr>
                </tbody>
            </table>
        </div>
    </div>


    <!-- Modal -->
<div class="modal fade" id="nuevoUsuario" tabindex="-1" role="dialog" aria-labelledby="nuevoUsuario" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Crear nuevo Usuario</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Nombre</label>
                  <input type="text" class="form-control" name="nombre" [(ngModel)]="nombre" placeholder="Nombre">
                </div>
                <div class="form-group col-md-6">
                    <label for="inputEmail4">Apellido</label>
                    <input type="text" class="form-control" name="apellido" [(ngModel)]="apellido" placeholder="Apellido">
                  </div>
              </div>
                <div class="form-row">
                  
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Email vendedor</label>
                    <input type="text" class="form-control" name="correo" [(ngModel)]="correo" placeholder="Correo Electrónico">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Teléfono</label>
                    <input type="text" class="form-control" name="telefono" [(ngModel)]="telefono" placeholder="Teléfono">
                  </div>
                </div>
                <div class="form-row">
                    
                    <div class="form-group col-md-6">
                      <label for="inputPassword4">Contraseña</label>
                      <input type="text" class="form-control" name="password" [(ngModel)]="password" placeholder="******">
                    </div>
                </div>
            </form>
            <div *ngIf="ok == true" class="alert alert-success alert-dismissible fade show" role="alert">
                Usuario agregado exitosamente.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div *ngIf="nok == true" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{error}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="createUser()">Guardar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="editarUsuario" tabindex="-1" role="dialog" aria-labelledby="editarUsuario" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Editar Usuario</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Nombre</label>
                  <input type="text" class="form-control" name="nombre" [(ngModel)]="nombre" placeholder="Nombre">
                </div>
                <div class="form-group col-md-6">
                    <label for="inputEmail4">Apellido</label>
                    <input type="text" class="form-control" name="apellido" [(ngModel)]="apellido" placeholder="Apellido">
                  </div>
              </div>
                <div class="form-row">
                  
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Email vendedor</label>
                    <input type="text" class="form-control" name="correo" [(ngModel)]="correo" placeholder="Correo Electrónico">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Teléfono</label>
                    <input type="text" class="form-control" name="telefono" [(ngModel)]="telefono" placeholder="Teléfono">
                  </div>
                </div>
            </form>
            <div *ngIf="ok == true" class="alert alert-success alert-dismissible fade show" role="alert">
                Usuario editado exitosamente.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div *ngIf="nok == true" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{error}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-primary" (click)="updateUser(id_user)">Guardar</button>
          </div>
      </div>
    </div>
  </div>


  <div class="modal fade" id="editarPass" tabindex="-1" role="dialog" aria-labelledby="editarPass" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Cambiar Contraseña</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form>
                <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="inputPassword4">Nueva Contraseña</label>
                      <input type="text" class="form-control" name="_password" [(ngModel)]="_password" placeholder="******">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="inputPassword4">Confirmar Contraseña</label>
                        <input type="text" class="form-control" name="_password2" [(ngModel)]="_password2" placeholder="******">
                      </div>
                </div>
            </form>
            <div *ngIf="ok == true" class="alert alert-success alert-dismissible fade show" role="alert">
                Contraseña editada exitosamente.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div *ngIf="nok == true" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{error}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="clearPass()" data-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-primary" (click)="updatePass(id_user)">Cambiar</button>
          </div>
      </div>
    </div>
  </div>






  </main><!-- /.container -->

