import { Component, OnInit } from '@angular/core';
import { RestService } from '../service/rest.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  users: any = [];

  id_user: string = "";
  nombre: string = "";
  apellido: string = "";
  correo: string = "";
  telefono: string = "";
  password: string = "";
  _password: string = "";
  _password2: string = "";
  tipo_iser: string = "";


  error: string = "";
  ok: boolean = false;
  nok: boolean = false;

  constructor(private _RestService: RestService) {
    
   }

  ngOnInit(): void {

    

    this.getUsers();
  

  }



  createUser(){
    this.nok = false;
    if(this.nombre != "" && this.apellido != "" &&
      this.correo != "" && this.telefono != "" &&
      this.password != ""){
      const regex : RegExp = /^[a-zA-Z0-9]{6,10}$/;
      if(regex.test(this.password))
      {
        const data = {
          nombre: this.nombre,
          apellido: this.apellido,
          correo: this.correo,
          telefono: this.telefono,
          password: this.password,
          tipo_iser: "1"
        }

        this._RestService.createUser(data).subscribe((res: any)=>{
          console.log(res);
          if(res.message == "Correo ya existe"){
            this.nok = true;
            this.error = "Correo ya existe";
            setTimeout(()=>{ this.nok = false }, 10000)
          }else{
            this.ok = true;
            this.getUsers();
            this.clear();
          }


        }) 
      }else{
        this.nok = true;
        this.error = "La contraseña debe tener un largo mínimo de 6 y máximo 10.";

      }
    }else{
      this.nok = true;
      this.error = "Debe completar todos los campos.";

    }

  }

  editUser(id,nombre,apellido,correo,telefono){
    this.clearPass();
    this.id_user = id;
    this.nombre = nombre;
    this.apellido = apellido;
    this.correo = correo;
    this.telefono = telefono;
    console.log(id);
  }

  updateUser(id){
    this.nok = false;
    if(this.nombre != "" && this.apellido != "" &&
      this.correo != "" && this.telefono != ""){

      const data = {
        nombre: this.nombre,
        apellido: this.apellido,
        correo: this.correo,
        telefono: this.telefono,
        tipo_iser: "1"
      }

      this._RestService.updateUser(id, data).subscribe((res: any)=>{
        console.log(res);
        if(res.message == "OK"){
          this.ok = true;
          this.getUsers();
        }
      });
    }else{
      this.nok = true;
      this.error = "Debe completar todos los campos.";
    }

  }

  updatePass(id){
    const regex : RegExp = /^[a-zA-Z0-9]{6,10}$/;
    if(regex.test(this._password))
    {
        if(this._password !== this._password2){
          console.log("que paja");
          this.nok = true;
          this.error = "Las contraseñas no coinciden";
          setTimeout(()=>{ this.nok = false }, 10000)
        }else{
          this.nok = false;
          const data = {
            password: this._password,
          }
    
          this._RestService.updateUserPass(this.id_user, data).subscribe((res: any)=>{
            console.log(res);
            if(res.message == "OK"){
              this.ok = true;
              this.clearPass();
            }
          });
        }
    }else{
      this.nok = true;
      this.error = "La contraseña debe tener un largo mínimo de 6 y máximo 10.";
      setTimeout(()=>{ this.nok = false }, 10000)
    }

  }

  clearPass(){
    this._password = "";
    this._password2 = "";
  }

  delUser(id,nombre,apellido){
    console.log(id);
    if(confirm('Desea eliminar el usuario?')){
      this._RestService.deleteUser(id).subscribe((res:[])=>{
        console.log(res);
        this.getUsers();
      })
    }
  }

  getUsers() {
    this._RestService.getUsers().subscribe((res:[])=>{
      console.log(res);
      if(!res.hasOwnProperty('message')){
        this.users = res;
      }
    });
  }

  clear(){
    this.nombre="";
    this.apellido="";
    this.correo="";
    this.telefono="";
    this.password="";
    this.tipo_iser="";

    setTimeout(()=>{ this.ok = false }, 4000)
  }

}


