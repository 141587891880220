import { Injectable } from '@angular/core';
import { Request } from '../interface/request';
import { apiUrl } from 'src/environments/environment';
import { tap, catchError} from 'rxjs/operators'
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
})

export class AuthService{

    constructor(private _Http:HttpClient, private _Router:Router){}

    setLocalStorage(request: Request){
        localStorage.setItem('Nombre',request.nombre)
        localStorage.setItem('Profile',request.tipo)
        return (request.hasOwnProperty('token') && localStorage.setItem('Token', request.token));
    }

    loggedIn(){
        return !!localStorage.getItem('Token');
    }

    getToken(){
        return localStorage.getItem('Token');
    }

    logOut(){
        localStorage.removeItem('Token');
        localStorage.removeItem('Nombre');
        localStorage.removeItem('Profile');
        this._Router.navigate(['/login']);
    }

    logIn(data){
        return this._Http.post<Request>(apiUrl + 'login', data).pipe(
            tap((res)=>{
                this.setLocalStorage(res);
                this._Router.navigate(['/home']);
            }),
            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                            token:error.token
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                            token:''
                        }
                    }
                    res.next(reqData);
                })
            })     
        )
    }


    regUser(data){

        return this._Http.post(apiUrl + 'register', data).pipe(

            catchError((err)=>{
                const {error} = err

                return new Observable((res)=>{
                    let reqData = {};
                    if (err.status === 401){
                        reqData = {
                            message:error.message,
                            status:error.status,
                            token:error.token
                        }
                    }else{
                        reqData = {
                            message:error.statusText,
                            status:error.status,
                            token:''
                        }
                    }
                    res.next(reqData);
                })
            })
        )
    }


    getProfile(){
        const token = localStorage.getItem('Token');
        return this._Http.get(apiUrl+'getProfile',{
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            })
        }).pipe(

            catchError((error)=>{
                return new Observable((res)=>{
                    const reqData = {
                        message:error.statusText,
                        status:error.status
                    }

                    res.next(reqData);
                })
            })

        )
    }



}

