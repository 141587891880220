import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { RestService } from '../service/rest.service';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {
  
  data: Array<{'Nombre': string, 'Rut': string, 'Email': string}> = []; 
  rows = [];

  filteredData = [];
  columnsWithSearch : string[] = [];
  selected: any[] = [];
  SelectionType = SelectionType;

  temp = [];
  columns = [{ prop: 'Nombre', name: 'nombre'}, { prop: 'Rut' , name: 'rut'}, { prop: 'Email', name: 'email' }];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  encapsulation: ViewEncapsulation.None;

  constructor(private _RestService: RestService) { }

  ngOnInit(): void {

    this._RestService.getClientes().subscribe((res:any)=>{

      console.log(res);
      res.forEach(e => {

        this.data.push( {Nombre: e['vendedor'], Rut: e['rut_vendedor'], Email: e['email_vendedor']});
      })
      
      this.rows = [...this.data];
      this.filteredData = this.rows;
      this.columnsWithSearch = Object.keys(this.rows[0]);
    })

  }


    // filters results
filterDatatable(event){
  // get the value of the key pressed and make it lowercase
  let filter = event.target.value.toLowerCase();

  // assign filtered matches to the active datatable
  this.rows = this.filteredData.filter(item => {
    // iterate through each row's column data
    for (let i = 0; i < this.columnsWithSearch.length; i++){
      var colValue = item[this.columnsWithSearch[i]] ;

      // if no filter OR colvalue is NOT null AND contains the given filter
      if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
        // found match, return true to add to result set
        return true;
      }
    }
  });
  // TODO - whenever the filter changes, always go back to the first page
  //this.table.offset = 0;
}

}
