<nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
    <a class="navbar-brand" href="#">EHRENFELD</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item" [routerLink]="['/home']" routerLinkActive="is-active">
              <a class="nav-link" href="home">Inicio <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item" [routerLink]="['/clientes']" routerLinkActive="is-active">
              <a class="nav-link" href="clientes">Clientes</a>
            </li>
            <li class="nav-item" [routerLink]="['/profile-view']" routerLinkActive="is-active">
              <a class="nav-link" href="profile-view">Operaciones</a>
            </li>
            <li class="nav-item " [routerLink]="['/usuarios']" routerLinkActive="is-active">
                <a class="nav-link" href="usuarios">Usuarios</a>
            </li>
            
          </ul>
      <form class="form-inline my-2 my-lg-0">
        <button class="btn btn-light" (click)="logOut()">Salir</button>
      </form>
    </div>
  </nav>