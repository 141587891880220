<section class="login container-fluid">
    <div class="container">
    <div class="row pt-5">
      <div class="col-md-4 col-sm-12">
        <img style="opacity: 0.8;" width="200" src="/assets/images/EH_LOGO-02.png">
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-center">
        <h1 class="welcome"></h1>
      </div>
    </div>
    <div class="row pt-5">

      <div class="col-md-6">
        
        <form class="light-red">
          <div class="form-group row">
            <div class="col-sm-12">
              <h5>Seguimiento Clientes</h5>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label for="num_seg">Nº Seguimiento</label>
            </div>
              <div class="col-md-7">
                  <input type="text" class="form-control" placeholder="Nº Seguimiento" name="num_seg" [(ngModel)]="seguimiento">
              </div>
          </div>
          <div class="form-group row">
            <div class="col-md-4">
              <label for="cod_op">Código Operación</label>
            </div>
              <div class="col-md-7">
                  <input type="password" class="form-control" placeholder="Código Operación" name="cod_op" [(ngModel)]="codigo">
              </div>
          </div>
          <div class="form-group row">
              <div class="col-sm-12 text-center">
              <button type="submit" class="btn btn-primary btn-white" (click)="trakking()">Buscar</button>
              </div>
          </div>
        </form>
      </div>
  
      <div class="col-md-6 spacer-200">
        
        <form class="light-red">
          <div class="form-group row">
            <div class="col-sm-12">
              <h5>Ingreso Ejecutivas</h5>
            </div>
          </div>
          <div class="form-group row">
              <label for="inputEmail" class="col-sm-3 col-form-label">Usuario</label>
              <div class="col-sm-8">
                  <input type="email" class="form-control" placeholder="Email" name="email" [(ngModel)]="email">
              </div>
          </div>
          <div class="form-group row">
              <label for="inputPassword" class="col-sm-3 col-form-label">Clave</label>
              <div class="col-sm-8">
                  <input type="password" class="form-control" placeholder="Password" name="pass" [(ngModel)]="pass">
              </div>
          </div>
          <div class="form-group row">
              <div class="col-sm-12 text-center">
                  <button type="submit" class="btn btn-primary btn-white" (click)="loginUser()">Entrar</button>
              </div>
          </div>
        </form>
      </div>
    </div>
    
    
  
  </div>
  </section>