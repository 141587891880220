<app-navbar></app-navbar>

<div id="pdfTable" #pdfTable class="container color-gris pt-5 pb-5">
    <div class="row">
        <div class="col-md-3">
            <img width="200" src="/assets/images/EH_LOGO-02.png">
        </div>
        <div class="col-md-6 mt-5">
            <p>
              <strong>Operación:</strong> {{profile.nom_op}}<br>
              <button *ngIf="tipo" class="btn btn-outline-danger float-right" href="#" data-toggle="modal" data-target="#exampleModal" data-html2canvas-ignore="true"><i class="bi bi-plus-square"></i> Editar</button>
            </p>
            <p>
                <strong>Vendedor:</strong> {{profile.vendedor}}<br>
                <strong>Rut:</strong> {{profile.rut_vendedor}}<br>
                <strong>Email:</strong> {{profile.email_vendedor}}
            </p>
            <p>
              <strong>Comprador:</strong> {{profile.comprador}}<br>
              <strong>Rut:</strong> {{profile.rut_comprador}}<br>
              <strong>Email:</strong> {{profile.email_comprador}}
            </p>    
            <p><strong>Fecha de firma promesa de CV:</strong> {{profile.fch_firm_cv}}</p>
            <p>
              <strong>Plazos:</strong> {{profile.plazos}}<br>
              <strong>Valor venta:</strong> {{profile.valor_venta}}<br>
              <strong>Tipo OP:</strong> {{profile.tipo_op}}<br>
              <strong>Abogado a cargo:</strong> {{profile.abogado}}<br>
              <strong>Ejecutiva(s) vendedoras:</strong> {{profile.eje_vende}}<br>
            </p>
            <p>
              <strong>Ejecutiva comercial:</strong> {{profile.ejecutiva}}<br>
                <strong>Email:</strong> {{profile.email_ejecutiva}}
            </p>
            <p>
              <strong>Carpeta:</strong> {{profile.carpeta}}<br>
            </p>
            
        </div>
        <div class="col-md-3 mt-5">
          <div class="card">
            <div class="card-body">
              <h4>N° SEGUIMIENTO</h4>
              <h1>{{profile.idficha}}</h1>
              <h5>Código: {{profile.codigo}}</h5>
            </div>
          </div>
          <button style="width: 100%;" class="btn btn-outline-danger btn-lg center mt-2" type="button" (click)="convetToPDF()" data-html2canvas-ignore="true">Descargar PDF</button>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
        
            <ul class="nav nav-tabs">
                
                <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#menu1" ><strong>SEGUIMIENTO/ESTADO OP</strong></a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu2"><strong>CONTACTOS A CARGO OP</strong></a></li>
                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#menu3"><strong>ARCHIVOS RELACIONADOS</strong></a></li>
              </ul>
              <div class="tab-content">
                
                <div id="menu1" class="tab-pane fade active show">

                    <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">FECHA</th>
                            <th scope="col">ETAPA</th>
                            <th scope="col">ESTADO DE OPERACIÓN/ SEGUIMIENTO</th>
                            <th scope="col">OBSERVACIONES</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let est of estados">
                            <td>{{est.fecha}}</td>
                            <td>{{est.etapa}}</td>
                            <td>{{est.estado_op}}</td>
                            <td>{{est.descripcion}}</td>
                            <td data-html2canvas-ignore="true"><a class="est-del mr-2" data-toggle="modal" data-target="#editstate" (click)="editState(est.idestado,est.fecha,est.etapa,est.estado_op,est.descripcion)"><i _ngcontent-ohq-c51="" class="bi bi-pencil-square"></i></a><a class="est-del" (click)="deleteEstate(est.idestado)"><i class="bi bi-trash"></i></a></td>
                          </tr>
                        </tbody>
                      </table>
                      <button *ngIf="tipo" class="btn btn-outline-danger float-right" href="#" data-toggle="modal" data-target="#addstate" data-html2canvas-ignore="true"><i class="bi bi-plus-square"></i> Agregar</button>

                </div>


                <div id="menu2" class="tab-pane fade">
                    <table class="table">
                        <thead>
                          <tr>
                            
                            <th scope="col">Nombre</th>
                            <th scope="col">Cargo</th>
                            <th scope="col">Teléfono</th>
                            <th scope="col">Email</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let con of contactos">
                            
                            <td>{{con.nombre}}</td>
                            <td>{{con.cargo}}</td>
                            <td>{{con.telefono}}</td>
                            <td>{{con.correo}}</td>
                            <td data-html2canvas-ignore="true"><a class="est-del mr-2" data-toggle="modal" data-target="#editContacto" (click)="editContacto(con.idcontacto,con.nombre,con.cargo,con.telefono,con.correo)"><i _ngcontent-ohq-c51="" class="bi bi-pencil-square"></i></a><a class="est-del" (click)="deleteContacto(con.idcontacto)"><i class="bi bi-trash"></i></a></td>
                          </tr>
                          
                        </tbody>
                      </table>
                      <button *ngIf="tipo" class="btn btn-outline-danger float-right" href="#" data-toggle="modal" data-target="#addcontact" data-html2canvas-ignore="true"><i class="bi bi-plus-square"></i> Agregar</button>
              
                </div>


                <div id="menu3" class="tab-pane fade">
                    <table class="table">
                        <thead>
                          <tr>
                            
                            <th scope="col">Fecha</th>
                            <th scope="col">Responsable</th>
                            <th scope="col">Documento</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let up of uploads">
                            
                            <td>{{up.fecha | date: 'dd/MM/yyyy'}}</td>
                            <td>{{up.nombre}}</td>
                            <td>{{up.ruta.toString().split("/")[2]}}</td>
                            <td data-html2canvas-ignore="true"><a class="est-del" (click)="viewUpload(up.iddoc,up.ruta )"><i class="bi bi-eye"></i></a></td>
                            <td data-html2canvas-ignore="true"><a class="est-del" (click)="deleteUpload(up.iddoc)"><i class="bi bi-trash"></i></a></td>
                          </tr>

                          
                        </tbody>
                      </table>
                      <div *ngIf="tipo" class="input-group" data-html2canvas-ignore="true">
                        <div class="input-group-prepend">
                          <span  class="input-group-text upload" (click)="upload()" id="btnUpload">Subir</span>
                        </div>
                        <div class="custom-file">
                            <form action="/api/upload" method="post" enctype="multipart/form-data">
                              <input (change)="fileChange($event)" type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                            </form>
                            <label class="custom-file-label" for="inputGroupFile01">{{fileName}}</label>
                        </div>
                      </div>
                    
                </div>
              </div>
        
        </div>
           
    </div>

    

<!-- Modal Editar top -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Crear nueva Operación</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Nombre Operación</label>
                <input type="text" class="form-control" name="nom_op" [(ngModel)]="nom_op" placeholder="Nombre Operación">
              </div>
            </div>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="inputEmail4">Nombre vendedor</label>
                  <input type="text" class="form-control" name="nom_vend" [(ngModel)]="nom_vend" placeholder="nombre completo">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Rut vendedor</label>
                  <input type="text" class="form-control" name="rut_vend" [(ngModel)]="rut_vend" placeholder="1111111-1">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Email vendedor</label>
                  <input type="text" class="form-control" name="email_vend" [(ngModel)]="email_vend" placeholder="Correo Electrónico">
                </div>
              </div>
              <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Nombre comprador</label>
                    <input type="text" class="form-control" name="nom_comp" [(ngModel)]="nom_comp" placeholder="nombre completo">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Rut comprador</label>
                    <input type="text" class="form-control" name="rut_comp" [(ngModel)]="rut_comp" placeholder="1111111-1">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Email comprador</label>
                    <input type="text" class="form-control" name="email_comp" [(ngModel)]="email_comp" placeholder="Correo Electrónico">
                  </div>
                </div>

              <div class="form-row">
                  <div class="form-group col-md-6">
                      <div class="form-group">
                      <label for="inputAddress">Fecha de firma promesa de CV</label>
                      <input type="text" class="form-control" name="fch_fir_cv" [(ngModel)]="fch_fir_cv" placeholder="10/05/2020">
                      </div>
                  </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputAddress">Plazos</label>
                    <input type="text" class="form-control" name="plazos" [(ngModel)]="plazos" placeholder="">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputAddress">Valor venta</label>
                  <input type="text" class="form-control" name="valor_venta" [(ngModel)]="valor_venta" placeholder=""> 
                </div>
              </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                  
                    <label for="inputAddress">Tipo de OP</label>
                    <input type="text" class="form-control" name="tipo_op" [(ngModel)]="tipo_op" placeholder="">
                  
              </div>
              <div class="form-group col-md-6">
               
                <label for="inputAddress">Abogado a cargo</label>
                <input type="text" class="form-control" name="abogado" [(ngModel)]="abogado" placeholder="">
                
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                 
                  <label for="inputAddress">Ejecutiva(s) vendedoras</label>
                  <input type="text" class="form-control" name="eje_vende" [(ngModel)]="eje_vende" placeholder="">
                 
              </div>
            </div>


              <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Ejecutivo Ehrefeld</label>
                    <input type="text" class="form-control" name ="nom_ejec" [(ngModel)]="nom_ejec" placeholder="nombre completo">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputPassword4">Email ejecutivo Ehrefeld</label>
                    <input type="email" class="form-control" name="email_ejec" [(ngModel)]="email_ejec" placeholder="Correo Electrónico">
                  </div>
                </div>
            </form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Nº Carpeta</label>
                <input type="text" class="form-control" [(ngModel)]="num_carp" placeholder="Nº Carpeta">
              </div>
              <div class="form-group col-md-6">
                <label for="inputEmail4">Seleccione color</label>
                <select name="op_etapa" class="form-control" [(ngModel)]="color">
                  <option value="1">Seleccione ...</option>
                  <option value="Red">Rojo</option>
                  <option value="Lime">Limón</option>
                  <option value="Aqua">Aqua</option>
                  <option value="Fuchsia">fucsia</option>
                  <option value="Blue">Azul</option>
                </select>
                <span class="show-color"><i class="bi bi-circle-fill" style="color:{{color}}"></i></span>
              </div>
            </div>
            

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="updateProfile()" data-dismiss="modal">Guardar</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal agregar estado -->
<div id="addstate" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addstate" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Crear seguimiento</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Fecha</label>
                <input type="text" class="form-control" name="op_fecha" [(ngModel)]="op_fecha" placeholder="18/04/2021">
              </div>

                <div class="form-group col-md-6">
                  <label for="inputEmail4">Etapa</label>
                  <select name="op_etapa" class="form-control" [(ngModel)]="op_etapa">
                    <option value="1">Seleccione ...</option>
                    <option value="Carta Oferta">Carta Oferta</option>
                    <option value="Formalización de oferta">Formalización de oferta</option>
                    <option value="Oferta aceptada">Oferta aceptada</option>
                    <option value="Reserva">Reserva</option>
                    <option value="Cierre de negocio y redacción de Promesa de CV">Cierre de negocio y redacción de Promesa de CV</option>
                    <option value="Firma de Promesa de CV">Firma de Promesa de CV</option>
                    <option value="Recopilación de antecedentes carpeta legal">Recopilación de antecedentes carpeta legal</option>
                    <option value="Tasación (con Banco)">Tasación</option>
                    <option value="Entrega de carpeta legal al abogado asignado o Banco">Entrega de carpeta legal al abogado asignado o Banco</option>
                    <option value="Estudio de títulos">Estudio de títulos</option>
                    <option value="Aprobación de títulos">Aprobación de títulos</option>
                    <option value="Escrituración">Escrituración</option>
                    <option value="Revisión de Esc. CV y redacción de instrucciones">Revisión de Esc. CV y redacción de instrucciones</option>
                    <option value="Firma clientes de Escritura de CV">Firma clientes de Escritura de CV</option>
                    <option value="Solicitud de alzamiento (si hay Banco)">Solicitud de alzamiento</option>
                    <option value="En proceso de alzamiento / Carta de resguardo">En proceso de alzamiento / Carta de resguardo</option>
                    <option value="En proceso / Seguimiento">En proceso / Seguimiento</option>
                    <option value="Firma del Banco alzante">Firma del Banco alzante</option>
                    <option value="Firma del banco comprador">Firma del banco comprador</option>
                    <option value="Cierre de copias en Notaría">Cierre de copias en Notaría</option>
                    <option value="Ingreso al CBR">Ingreso al CBR</option>
                    <option value="Reparo en CBR">Reparo en CBR</option>
                    <option value="Inscripción en el CBR">Inscripción en el CBR</option>
                    <option value="Solicitud de documentos de inscripción">Solicitud de documentos de inscripción</option>
                    <option value="Liberación de instrucciones notariales">Liberación de instrucciones notariales</option>
                    <option value="Pago al Banco alzante">Pago al Banco alzante</option>
                    <option value="Pago del remanente al vendedor">Pago del remanente al vendedor</option>
                    <option value="Entrega de la propiedad">Entrega de la propiedad</option>
                    <option value="Cobro de los honorarios">Cobro de los honorarios</option>
                    <option value="Facturación">Facturación</option>
                    <option value="Envío de boletas de honorarios">Envío de boletas de honorarios</option>
                    <option value="OPERACIÓN CERRADA">OPERACIÓN CERRADA</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Estado de operación / Seguimiento</label>
                  <input type="text" class="form-control" name="op_estado" [(ngModel)]="op_estado" placeholder="ESTADO DE OPERACIÓN/ SEGUIMIENTO">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Observaciones</label>
                  <input type="text" class="form-control" name="op_obs" [(ngModel)]="op_obs" placeholder="Observaciones">
                </div>
              </div>
            </form>
            <span style="color:red">{{errorEstado}}</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="createState($event)">Guardar</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal editar estado -->
<div id="editstate" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addstate" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Crear seguimiento</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Fecha</label>
                <input type="text" class="form-control" name="op_fecha" [(ngModel)]="op_fecha" placeholder="18/04/2021">
              </div>

                <div class="form-group col-md-6">
                  <label for="inputEmail4">Etapa</label>
                  <select name="op_etapa" class="form-control" [(ngModel)]="op_etapa">
                    <option value="1">Seleccione ...</option>
                    <option value="Carta Oferta">Carta Oferta</option>
                    <option value="Formalización de oferta">Formalización de oferta</option>
                    <option value="Oferta aceptada">Oferta aceptada</option>
                    <option value="Reserva">Reserva</option>
                    <option value="Cierre de negocio y redacción de Promesa de CV">Cierre de negocio y redacción de Promesa de CV</option>
                    <option value="Firma de Promesa de CV">Firma de Promesa de CV</option>
                    <option value="Recopilación de antecedentes carpeta legal">Recopilación de antecedentes carpeta legal</option>
                    <option value="Tasación (con Banco)">Tasación (con Banco)</option>
                    <option value="Entrega de carpeta legal al abogado asignado o Banco">Entrega de carpeta legal al abogado asignado o Banco</option>
                    <option value="Estudio de títulos">Estudio de títulos</option>
                    <option value="Aprobación de títulos">Aprobación de títulos</option>
                    <option value="Escrituración">Escrituración</option>
                    <option value="Revisión de Escritura de CV e Instrucciones notariales">Revisión de Escritura de CV e Instrucciones notariales</option>
                    <option value="Firma clientes de Escritura de CV">Firma clientes de Escritura de CV</option>
                    <option value="Solicitud de alzamiento">Solicitud de alzamiento</option>
                    <option value="Firma del Banco alzante">Firma del Banco alzante</option>
                    <option value="Firma del banco comprador">Firma del banco comprador</option>
                    <option value="Cierre de copias en Notaría">Cierre de copias en Notaría</option>
                    <option value="Ingreso al CBR">Ingreso al CBR</option>
                    <option value="Reparo en CBR">Reparo en CBR</option>
                    <option value="Inscripción en el CBR">Inscripción en el CBR</option>
                    <option value="Solicitud de documentos de inscripción">Solicitud de documentos de inscripción</option>
                    <option value="Liberación de instrucciones notariales">Liberación de instrucciones notariales</option>
                    <option value="Pago al Banco alzante">Pago al Banco alzante</option>
                    <option value="Pago del remanente al vendedor">Pago del remanente al vendedor</option>
                    <option value="Entrega de la propiedad">Entrega de la propiedad</option>
                    <option value="Cobro de los honorarios">Cobro de los honorarios</option>
                    <option value="Envío de boletas de honorarios">Envío de boletas de honorarios</option>
                    <option value="OPERACIÓN CERRADA">OPERACIÓN CERRADA</option>
                  </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Estado de operación / Seguimiento</label>
                  <input type="text" class="form-control" name="op_estado" [(ngModel)]="op_estado" placeholder="ESTADO DE OPERACIÓN/ SEGUIMIENTO">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Observaciones</label>
                  <input type="text" class="form-control" name="op_obs" [(ngModel)]="op_obs" placeholder="Observaciones">
                </div>
              </div>
            </form>
            <span style="color:red">{{errorEstado}}</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="updateState(op_id)">Guardar</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal agregar contacto -->
<div id="addcontact" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addcontact" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Crear Contacto</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Nombre</label>
                <input type="text" class="form-control" name="con_nombre" [(ngModel)]="con_nombre" placeholder="Nombre completo">
              </div>

                <div class="form-group col-md-6">
                  <label for="inputEmail4">Cargo</label>
                  <input type="text" class="form-control" name="con_cargo" [(ngModel)]="con_cargo" placeholder="Cargo">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Teléfono</label>
                  <input type="text" class="form-control" name="con_tel" [(ngModel)]="con_tel" placeholder="5555 5555">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Correo</label>
                  <input type="text" class="form-control" name="con_correo" [(ngModel)]="con_correo" placeholder="ejemplo@ejemplo.com">
                </div>
              </div>
            </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="createContacto($event)">Guardar</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal Editar contacto -->
<div id="editContacto" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="editContacto" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Crear Contacto</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputEmail4">Nombre</label>
                <input type="text" class="form-control" name="con_nombre" [(ngModel)]="con_nombre" placeholder="Nombre completo">
              </div>

                <div class="form-group col-md-6">
                  <label for="inputEmail4">Cargo</label>
                  <input type="text" class="form-control" name="con_cargo" [(ngModel)]="con_cargo" placeholder="Cargo">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Teléfono</label>
                  <input type="text" class="form-control" name="con_tel" [(ngModel)]="con_tel" placeholder="5555 5555">
                </div>
                <div class="form-group col-md-6">
                  <label for="inputPassword4">Correo</label>
                  <input type="text" class="form-control" name="con_correo" [(ngModel)]="con_correo" placeholder="ejemplo@ejemplo.com">
                </div>
              </div>
            </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        <button type="button" class="btn btn-primary" (click)="updateContacto($event)">Guardar</button>
      </div>
    </div>
  </div>
</div>


</div>



